String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

$(function () {
    $('.enable-submit-on-js-loaded').removeClass('d-none');
});

/**
 * Invia una richiesta ajax e, in caso la sessione sia scaduta ma venga eseguito un autologin da cookie, re-invia la richiesta
 * @param requestUrl
 * @param callback
 * @param counter
 */
function tryXMLHttpRequest(requestUrl, callback, counter) {
    if (typeof counter == 'undefined') {
        counter = 0;
    }
    // console.log('tryXMLHttpRequest', requestUrl, counter);
    if (counter < 2) {
        var request = new XMLHttpRequest();
        request.open('GET', requestUrl, true);

        request.onload = function () {
            var responseUrl = request.responseURL;
            responseUrl = responseUrl.replace(window.location.origin+'/', '');

            // console.log(window.location.origin);
            // console.log(responseUrl, encodeURI(requestUrl));

            if (request.status >= 200 && request.status < 400) {

                if (responseUrl == encodeURI(requestUrl)) {
                    callback(request.response);
                } else {
                    tryXMLHttpRequest(requestUrl, callback, counter + 1)
                }
            }
        };

        request.send();
    }
}

function copyToClipboard(text) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
}

function showPassword(text) {
    $(".password-wrapper").html(text);
    $(".password-show").remove();
}