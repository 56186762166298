// onsubmit="return submitForm(this);"

var credentialAddUrl = '/api/credential/add';
var credentialEditUrl = '/api/credential/edit/';
var credentialDeleteUrl = '/api/credential/delete/';
var credentialAddModal = $('#credentialAddModal');

function addCredentialAttachment() {
    var html = $('#api_credential_credentialAttachments').attr('data-prototype');
    html = html.replaceAll('__credential_attachment', 'api_credential[credentialAttachments][__name__]');
    var item = $(html.replaceAll('__name__', Date.now() + Math.floor(Math.random() * 1000)));
    $('#api_credential_credentialAttachments').append(item);
}

function deleteCredentialAttachment(index) {
    $('#credential-attachment-'+index).remove();
}

function addCredential(type) {

    if (type == 'personal') {

        var requestParams = {
            'type': type,
            'user': userId
        };

        var requestParamsString = Object.keys(requestParams).map(function(key) {
            return key + '=' + requestParams[key];
        }).join('&');

        var request = new XMLHttpRequest();
        request.open('POST', credentialAddUrl, true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.onload = function () {

            if (request.status >= 200 && request.status < 400) {
                var response = JSON.parse(request.response);
                // console.log(response);

                credentialAddModal.find('.modal-body').html(response.html);
                credentialAddModal.modal('show');

                /* funzione che mostra il nome del file ogni volta che un file viene selezionato */
                document.getElementById("api_credential_imageFile_file").onchange = function (e) {
                    var fileName = e.target.files[0].name;
                    document.getElementById("fileuploadurl").value = fileName;
                };
                /* *********************************** */
            }
        };
        request.send(requestParamsString);

    } else {
        return false;
    }
}

function editCredential(type, id) {

    if (type == 'personal') {

        var requestParams = {
            'type': type,
            'user': userId
        };

        var requestParamsString = Object.keys(requestParams).map(function(key) {
            return key + '=' + requestParams[key];
        }).join('&');

        var request = new XMLHttpRequest();
        request.open('POST', credentialEditUrl + id, true);
        request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        request.onload = function () {

            if (request.status >= 200 && request.status < 400) {
                var response = JSON.parse(request.response);
                // console.log(response);

                credentialAddModal.find('.modal-body').html(response.html);
                credentialAddModal.modal('show');
            }
        };
        request.send(requestParamsString);

    } else {
        return false;
    }
}

function deleteCredential(type, id) {

    if (window.confirm('Sei sicuro di voler eliminare la credenziale?')) {

        if (type == 'personal') {

            var requestParams = {
                'type': type,
                'user': userId
            };

            var requestParamsString = Object.keys(requestParams).map(function(key) {
                return key + '=' + requestParams[key];
            }).join('&');

            var request = new XMLHttpRequest();
            request.open('POST', credentialDeleteUrl + id, true);
            request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            request.onload = function () {

                if (request.status >= 200 && request.status < 400) {
                    var response = JSON.parse(request.response);

                    if (response.result) {

                        var htmlEmptyDetail = '<div class="empty-list"><p class="text-center">Seleziona<br>una credenziale</p></div>';

                        credentialAddModal.modal('hide');
                        // $('.label-cat[data-id-cat='+response.categoryId+']').trigger('click');
                        $('.credentialList[data-cred-id='+id+']').remove();
                        $('#detail-cred').html(htmlEmptyDetail);
                    }
                }
            };
            request.send(requestParamsString);

        } else {
            return false;
        }
    }
}

$(function () {
    $(document).on('submit', '.credentialForm', function (e) {
        e.preventDefault();

        var formData = new FormData(this);

        var type = $(this).attr('data-type');
        var request = new XMLHttpRequest();
        var requestUrl = credentialAddUrl;
        var credentialId = $(this).attr('data-id');
        if (credentialId) {
            requestUrl = credentialEditUrl + credentialId;
        }
        request.open('POST', requestUrl + '?type=' + type, true);
        request.onload = function () {

            if (request.status >= 200 && request.status < 400) {
                var response = JSON.parse(request.response);

                if (response.insertedId) {
                    credentialId = response.insertedId;
                }

                if (response.result) {
                    credentialAddModal.modal('hide');

                    if (type == 'personal') {
                        $('#cont-0').find('.container-cat-lb').find('label').trigger('click');

                        var reloadCatRequest = new XMLHttpRequest();
                        reloadCatRequest.open('GET', 'get-credentials-for-category-permission?cat_id=0', true);
                        reloadCatRequest.onload = function () {

                            if (reloadCatRequest.status >= 200 && reloadCatRequest.status < 400) {
                                $('#list-cred').html(reloadCatRequest.response);
                                $('.cred-preview[data-cred-id='+credentialId+']').trigger('click');
                            }
                        };
                        reloadCatRequest.send();
                    }
                }

                credentialAddModal.find('.modal-body').html(response.html);
            }
        };
        request.send(formData);
    });
});
