console.log('electron INIT');

if (isElectron) {
    $('a[target="_blank"]').attr('target', '_self')
}

if (isElectron) {
    $('a[target="_blank"]').attr('target', '_self');

    $(document).on('click', 'a.js-credential-url', function (e) {
        e.preventDefault();
        console.log('js-credential-url');
        api.openExternalLink($(this).attr('data-href'));
    });
}