// var spinner = '<div class="spinner-container"><img src="/assets/images/spinner.gif"></div>';
// var spinnerOffsetTime = 500;

var activeCategory = 0;
var selectedCategory = false;

$(document).ready(function() {

    // tree view categorie
    $('.container-cat').each(function () {
        var parent = $(this).attr('data-parent');

        if (parent != 0) {
            $("#clps-" + parent).append($(this));
        }
    });

    var labelCatClickTime = null;
    $('.label-cat').click(function() {
        labelCatClickTime = Date.now();
        var currentLabelCatClickTime = labelCatClickTime;
        var element = $(this);
        var cat_id = element.attr('data-id-cat');

        if(selectedCategory) {
            selectedCategory.removeClass('selected');
        }
        selectedCategory = $('#cont-lb-' + cat_id);
        selectedCategory.addClass('selected');

        // console.log(element.attr('data-open-collapse'));

        if (parseInt(element.attr('data-open-collapse'))) {
            $('#clps-'+cat_id).collapse('toggle');
        }

        if(activeCategory !== cat_id) {
            var emptyHtml = "<div class=\"empty-list\">" +
                "<p class=\"text-center\">Seleziona<br>una credenziale</p></div>";

            $('#detail-cred').html(emptyHtml);

            tryXMLHttpRequest('get-credentials-for-category-permission?cat_id=' + cat_id, function (response) {
                $('#list-cred').html(response);
            });

            activeCategory = cat_id;
        }
    });

    // MOBILE TOGGLE

    // MENU
    $("#navigationLeft .label-cat_last").on('click', function(event){
        event.preventDefault();
        if($(window).width() <= 991){
            $('#sidebarToggleTop').removeClass("open");
            $('#navigationLeft').removeClass("open");
            $(".credentialsDetail").removeClass("open");
        }
    });
    $("#navigationLeft .menuLeft__personalArea").on('click', function(event){
        event.preventDefault();
        if($(window).width() <= 991){
            $('#sidebarToggleTop').toggleClass("open");
            $('#navigationLeft').removeClass("open");
        }
    });
    $('#sidebarToggleTop').on('click', function(){
        $('#sidebarToggleTop').toggleClass("open");
        $('#navigationLeft').toggleClass("open");
        $(".credentialsDetail").removeClass("open");
    });
    $(".openMobileMenu").on('click', function(event){
        event.preventDefault();
        if($(window).width() <= 991){
            $('#sidebarToggleTop').toggleClass("open");
            $('#navigationLeft').toggleClass("open");
            $(".credentialsDetail").removeClass("open");
        }
    });


    // FILTRI
    var $filterMenu = $('.filter-wrapper');
    if ($filterMenu.length) {
        $(document).mouseup(function (e) {
            if (!$filterMenu.is(e.target) && $filterMenu.has(e.target).length === 0) {
                $filterMenu.removeClass('open');
            }
        });
    }

    // RICERCA
    var $searchMenu = $('.search-wrapper');
    if ($searchMenu.length) {
        $(document).mouseup(function (e) {
            if (!$searchMenu.is(e.target) && $searchMenu.has(e.target).length === 0) {
                $searchMenu.removeClass('open');
            }
        });
    }

    $("#searchToggle").on('click', function(event){
        event.preventDefault();
        if($(window).width() <= 991){
            $('.search-wrapper').toggleClass("open");
        }
    });

    $("#filterToggle").on('click', function(event){
        event.preventDefault();
        // console.log('click');
        $(".filter-wrapper").toggleClass("open");
    });


});


$(document).ready(function() {

    var selectedCredential = false;

    var credPreviewClickTime = null;
    $(document).on('click', '.cred-preview', function () {
        credPreviewClickTime = Date.now();
        var currentCredPreviewClickTime = credPreviewClickTime;

        if(selectedCredential) {
            selectedCredential.removeClass('selected');
        }
        selectedCredential = $(this);
        selectedCredential.addClass('selected');

        var cred_id = $(this).attr('data-cred-id');

        // $('#detail-cred').html(spinner);

        tryXMLHttpRequest('get-credential-detail?cred_id=' + cred_id, function (response) {
            $('#detail-cred').html(response);

            console.log('isElectron', isElectron);

            setTimeout(function () {
                if (isElectron) {
                    var href = $('a.js-credential-url').attr('href');
                    $('a.js-credential-url').attr('href', 'javascript:void(0);')
                    $('a.js-credential-url').attr('data-href', href)
                }
            }, 500);
        });
    });

    $(document).on('change paste keyup', '.filter-text-box', function() {

        var key = $(this).val().toLowerCase();
        // console.log(key);

        if(key !== ''){
            $('.credentialList__title').each(function(){
                var credTitle = $(this).text().toLowerCase();
                // console.log(credTitle);
                var n = credTitle.indexOf(key);
                // console.log(n);

                if(n >= 0){
                    $(this).parents('.cred-preview').show();
                    var innerHTML = $(this).text().substring(0,n) + "<span class='highlight'>" + $(this).text().substring(n, n+key.length) + "</span>" + $(this).text().substring(n + key.length);
                    $(this).html(innerHTML);
                }
                else{
                    $(this).parents('.cred-preview').hide();
                }
            });
        } else{
            $('.credentialList__title').each(function(){
                $(this).html($(this).text());
                $(this).parents('.cred-preview').show();
            });
        }

    });

    // CREDENTIAL DETAIL
    $(document).on('click', '.credential-item', function(){
        // console.log('click');
        $(".credentialsDetail").addClass("open");
    });


    // $('.image-picker').onchange = function () {
    //     $('.placeholder-text')
    // }

    // $(document).on('change', '.image-picker', function() {

    // $(document).on('click', '.form-control-file', function() {
    //     // $('.image-picker').each(function() {
    //         var thisPicker = $('.image-picker').children('.placeholder-text');
    //         // var thisForm = $(this).children('.form-control-file');
    //         var thisForm = $(this).parent('.vich-file').parent('.btn').parent('.button_file').parent('.image-picker');
    //         console.log('1');
    //
    //         thisForm.on('change', function() {
    //             console.log('2');
    //             // $('.placeholder-text').value = this.value;
    //             document.getElementById("fileuploadurl").value = this.value;
    //             console.log(this.value);
    //         });
    //     // });
    // });


    // $(document).on('click', '.image-picker', function(){
    //     console.log('lvl 1');
    //     var thisPicker = $(this).children('.placeholder-text');
    //
    //     $('.form-control-file').onchange = function() {
    //         console.log('lvl 2');
    //         thisPicker.value = this.value;
    //     }
    //
    // });

    $("#passwordResetConfirm").on('click', function(){

        $('#passwordResetModal').modal('hide');

        var request = new XMLHttpRequest();
        request.open('GET', '/resetting/send-email?fromUserMenu=1', true);

        request.onload = function () {

            if (request.status >= 200 && request.status < 400) {
                $('#passwordResetEmailModal').modal('show');
            }
        };

        request.send();
    });

});
