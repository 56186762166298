var searchTime = null;
var lastSearchString = null;

function search(searchItem) {
    var suggestionsBox = searchItem.parent().find('.suggestions');
    var key = searchItem.val();
    searchTime = Date.now();
    var searchTimeCurrent = searchTime;
    // console.log(searchTimeCurrent.toString(), searchTime.toString());

    setTimeout(function () {

        // console.log('      ', searchTimeCurrent.toString(), searchTime.toString());

        if (searchTimeCurrent.toString() === searchTime.toString()) {

            if (!searchFormSubmitted) {
                if (key && key.length > 2) {

                    lastSearchString = key;

                    tryXMLHttpRequest('get-suggestions-for-search?key='+key, function (html) {
                        if (lastSearchString == key) {
                            suggestionsBox.html(html);
                            $('.suggestions').removeClass('d-none');
                        }
                    });
                } else {
                    suggestionsBox.html('');
                }
            }
            searchFormSubmitted = false;
        }
    }, 250);
}

$('.search-text-box').on('paste', function (e) {
    // console.log('trigger: ', 'paste', e.keyCode, $(this).val());
    search($(this));
});
$('.search-text-box').on('keyup', function (e) {
    if (e.keyCode != 40 && e.keyCode != 13) {
        // console.log('trigger: ', 'keyup', e.keyCode, $(this).val());
        search($(this));
    }
});

function loadSearchResultsFromSuggestions(catId) {
    if (parseInt(catId) == 0) {
        // search string
        $('.search-form:visible').trigger('submit');
    } else {
        if (catId != activeCategory) {
            // search category
            var parent = $('#cont-'+catId).attr('data-parent');
            while (!isNaN(parent) && parseInt(parent) > 0) {
                $('#clps-'+parent).collapse('show');
                parent = $('#cont-'+parent).attr('data-parent')
            }
            // triggera il click e apre il collapse
            $('.label-cat[data-id-cat="'+catId+'"]').trigger('click');
        }
    }
    setTimeout(function () {
        $('.suggestions').addClass('d-none');
    }, 200);
}

$(document).keydown(function(e) {

    // trigger evento "arrowdown" su input ricerca
    if ($(':focus').hasClass('search-text-box')) {
        // console.log('search-text-box');
        if (e.keyCode == 40) {
            e.preventDefault();
            if ($('.suggestions').find('.suggestion').length) {
                setTimeout(function () {
                    $('.suggestions').find('.suggestion[tabindex="0"]')[0].focus();
                }, 250);
            }
        }
    }

    // trigger evento "arrowdown" e "arrowup" sui suggerimenti
    if ($(':focus').hasClass('suggestion')) {

        // arrowdown
        if (e.keyCode == 40) {
            e.preventDefault();
            $(".suggestion:focus").next().focus();
        }
        // arrowup
        if (e.keyCode == 38) {
            e.preventDefault();
            $(".suggestion:focus").prev().focus();
        }
        // invio
        if (e.keyCode == 13) {
            e.preventDefault();
            var catId = $(e.target).attr('data-cat-id');
            // MOSTRA I RISULTATI
            loadSearchResultsFromSuggestions(catId);
        }

    }
});

// trigger evento click
$(document).click(function (e) {

    if ($(e.target).hasClass('suggestion') || $(e.target).parentsUntil('.suggestion').parent().hasClass('suggestion')) {
        // elemento .suggestion o figli --> ricerca
        if ($(e.target).hasClass('suggestion')) {
            var catId = $(e.target).attr('data-cat-id');
        } else {
            var catId = $(e.target).parentsUntil('.suggestion').parent().attr('data-cat-id');
        }
        if (typeof catId == 'undefined') {
            return;
        } else {
            // MOSTRA I RISULTATI
            loadSearchResultsFromSuggestions(catId);
        }
    } else if ($(e.target).hasClass('search-text-box')) {
        // click sull'input --> apertura tendina
        $('.suggestions').removeClass('d-none');
    } else {
        // elemento non suggestion (resto del body) --> chiusura tendina
        setTimeout(function () {
            $('.suggestions').addClass('d-none');
        }, 100);
    }
});

var searchFormSubmitTime = null;
var searchFormSubmitted = false;
$('.search-form').submit(function (event) {
    event.preventDefault();
    searchFormSubmitted = true;
    searchFormSubmitTime = Date.now();
    var currentSearchFormSubmitTime = searchFormSubmitTime;

    // var suggestionsBox = $(this).find('.suggestions');
    // var searchBox = $(this).find('.search-text-box');
    // searchBox.val('');
    // suggestionsBox.html('');

    var key = $(this).find('.search-text-box').val();

    if(key !== '') {

    }

    if(selectedCategory) {
        selectedCategory.removeClass('selected');
    }
    selectedCategory = false;
    activeCategory = null;

    tryXMLHttpRequest('get-credentials-for-search?key=' + key, function (response) {
        $('#list-cred').html(response);
    });
});